import { defineStore } from 'pinia'
import type { paths } from '~/types/schema'

interface CheckPasswordBody
  extends Omit<paths['/users/check_password/']['post']['requestBody']['content']['application/json'], 'password'> {
  action: 'create' | 'destroy'
}

export const usePasswordConfirmStore = defineStore('password-confirm-token', () => {
  const token = ref('')
  const authInfo = ref<CheckPasswordBody>()

  return { token, authInfo }
})
